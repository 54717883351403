import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  CircularProgress,
  createTheme,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material"
import { 
  Navigate,
  Route, 
  BrowserRouter as Router, 
  Routes, 
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom"
import {
  EnduserProvider,
  LoadingButton,
  LoadingLinear,
  useEnduserSession,
  useEnduserSessionContext,
  usePortalCustomizations,
  EnduserSession,
  WithEnduserSession,
} from "@tellescope/react-components"
import {
  useCurrentCallContext,
  useJoinVideoCall,
  WithVideo,
} from "@tellescope/video-chat"
import { routes } from './definitions/routes';
import { Register } from './pages/Register';
import { Login } from './pages/Login';
import { Home, WithSetInitialPasswordPrompt } from './pages/Home';
import { Logout } from './pages/Logout';
import { AuthenticatedContainer, WithVideoToast } from './components/navigation';
import { Chat } from './pages/Chat';
import { Settings } from './pages/Settings';
import { TellescopeHost } from './definitions/constants';
import { WithOrganizationTheme, useOrganizationTheme } from './definitions/contexts';
import { PRIMARY_HEX, SECONDARY_HEX } from "@tellescope/constants"
import { Community } from './pages/Community';
import { Documents, SubmitForm } from './pages/Documents/index';
import { Content, ContentViewer } from './pages/Content';
import { VideoCall } from './pages/VideoCall';
import { ResetPassword } from './pages/ResetPassword';
import PrivacyPolicy from './pages/Legal/PrivacyPolicy';
import TermsOfService from './pages/Legal/TermsOfService';
// import { Onboarding } from './pages/Onboarding';
import { Events, Event } from './pages/Events';
import { CarePlanHome } from './pages/CarePlan';
import { AppointmentScheduling, AppointmentSchedulingForTemplateRoute } from './pages/AppointmentScheduling';
import { LandingPage } from './pages/Landing';
import { PublicAppointmentBookingPage } from './pages/AppointmentBooking/AppointmentBooking';
import { Unsubscribe } from './pages/Unsubscribe';
import { WithFileViewer } from './components/files';
import { getApiURL } from '@tellescope/utilities';
import FormDisplay from './FormDisplay';
import { FormResponsePage } from './pages/FormResponse';
import { OutstandingForms } from './pages/Documents/OutstandingForms';
import { SubmittedForms } from './pages/Documents/SubmittedForms';
import { Files } from './pages/Documents/Files';
import { WithPreventEmbed } from './components/WithPreventEmbed';
import { Enduser } from '@tellescope/types-client';
import { CustomPageIframeLoading } from './pages/CustomPageIframe';
import { TERMS_VERSION, TermsOfServiceAndPrivacyPolicyConsent } from './components/agreements';
import { Orders } from './pages/Orders';

const LoadingPage = () => (
  <Grid container alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
    <CircularProgress size={250} thickness={1} />
  </Grid>
)

const App = () => {
  // resolving in test can be done with customPortalURL or subdomain - useful to test both paths for functionality
  const subdomain = (
    window.location.origin.includes(':3030')
      ? ( // use default subdomain for local testing
        '' // root is tellescope, non-reset account is tellescope-test
        // 'sub-organization'
      )
      : window.location.hostname.includes('tellescope.com')
        ? window.location.hostname.split('.')[0] // using 
        : undefined
  )

  const customPortalURL = (
    // window.location.origin.includes(':3030')
    //   ? undefined
      // : 
      window.location.hostname.includes('tellescope.com') 
        ? undefined
        : window.location.origin // using custom portal url
  )

  if (!(subdomain || customPortalURL)) return <Typography>This URL is invalid</Typography>
  return (
    <WithOrganizationTheme subdomain={subdomain} customPortalURL={customPortalURL}>
      <AppWithOrganizationInfo />
    </WithOrganizationTheme>
  )
}

export const AppWithOrganizationInfo = () => {
  const { theme, error } = useOrganizationTheme()
  const themingRef = useRef(false)

  useEffect(() => {
    if (!theme.name) return
    if (themingRef.current) return
    themingRef.current = true

    // set page title
    window.document.title = theme.name

    // set custom favicon
    if (!theme.faviconURL) return

    let link = window.document.querySelector("link[rel~='icon']");
    if (!link) {
        link = window.document.createElement('link');
        // @ts-ignore
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    // @ts-ignore
    link.href = theme.faviconURL;
  }, [theme])

  if (error) {
    return (
      <Grid container direction="column">
        <Typography>There was an error loading the portal. Please make sure the URL is valid and correctly configured</Typography>

        <Typography color="error" style={{ marginTop: 5 }}>
          {error}
        </Typography>
      </Grid>
    )
  }

  if (!theme?.name) return (
    <LoadingPage />
  )
  return (
    <ThemeProvider theme={createTheme({
      palette: {
        primary: {
          main: theme.themeColor ?? PRIMARY_HEX,
        },
        secondary: {
          main: theme.themeColorSecondary ?? SECONDARY_HEX,
        },
      }
    })}>
    <WithEnduserSession sessionOptions={{ 
      host: TellescopeHost,
      businessId: theme.businessId,
      organizationIds: theme.organizationIds,
      handleUnauthenticated: async () => { 
        // don't redirect public booking page if login is cached and expired
        if (window.location.href.includes('/appointment-booking-page')) return

        window.location.href = routes.login 
      },
    }}>
    <EnduserProvider>
    <Router>
      <Routing />
    </Router>
    </EnduserProvider>
    </WithEnduserSession>
    </ThemeProvider>  
  );
}

const RedirectURL = () => {
  const token = useParams().token as string
  if (token) {
    window.location.href = (
      `${getApiURL()}/r/${token}`
    )
  }
  return <></>
}

const genericRoutes = [
  <Route key="/privacy-policy" path={'/privacy-policy'} element={<PrivacyPolicy />} />,
  <Route key="/terms-of-service" path={'/terms-of-service'} element={<TermsOfService />} />,
  <Route key="/appointment-booking-page" path={"/appointment-booking-page"} element={<PublicAppointmentBookingPage />} />,
  <Route key="/unsubscribe" path={"/unsubscribe"} element={<Unsubscribe />} />,
  <Route key="/r/:token" path={"/r/:token"} element={<RedirectURL />} />,
]

const UnauthenticatedAppRoutes = ({ hideRegister } : { hideRegister?: boolean }) => (
  <Routes>
    <>{genericRoutes}</>
    <Route path={routes.landing} 
      element={
        hideRegister 
          ? <WithPreventEmbed><Login /> </WithPreventEmbed>
          : <WithPreventEmbed><LandingPage /></WithPreventEmbed>
      } 
    />
    <Route path={routes.login} element={<WithPreventEmbed><Login /></WithPreventEmbed>} />
    {hideRegister ? null : <Route path={routes.register} element={<WithPreventEmbed><Register /></WithPreventEmbed>} />}
    <Route path={routes.reset_password} element={<WithPreventEmbed><ResetPassword /></WithPreventEmbed>} />
    <Route path="/*" element={<Navigate replace to={routes.login} />} /> 
  </Routes>
)

const RequireTermsOfServiceAndPrivacyPolicy = ({ children, dontRequire } : { children: React.ReactElement, dontRequire: boolean }) => {
  const location = useLocation()
  const { enduserSession, updateUserInfo } = useEnduserSessionContext()
  const [consented, setConsented] = useState(false)

  const [searchParams] = useSearchParams()
  const embedded = searchParams.get('embedded')
  if (embedded === 'true') return children
  if (location.search.includes('token=')) return children

  // allow accessing these pages
  if (location.pathname.includes('/terms-of-service')) return children
  if (location.pathname.includes('/privacy-policy')) return children


  if (!enduserSession.userInfo.termsSigned && !dontRequire) {
    return (
      <Grid container alignItems="center" justifyContent="center" sx={{ minHeight: '100vh' }}>
      <Grid container direction="column" sx={{ maxWidth: 300 }}>
        <Typography sx={{ fontSize: 20, mb: 1, textAlign: 'center' }}>
          You must accept our Terms of Service and Privacy Policy to continue  
        </Typography> 

        <Grid item sx={{ mt: 2, mb: 1 }}>
          <TermsOfServiceAndPrivacyPolicyConsent value={consented} onChange={setConsented} />
        </Grid>

        <LoadingButton submitText='Submit' submittingText="Saving..."
          disabled={!consented}
          onClick={() => updateUserInfo({
            termsSigned: new Date(),
            termsVersion: TERMS_VERSION,
          })} 
        />
      </Grid>
      </Grid>
    )
  }

  return children
}

const AuthenticatedAppRoutes = () => {
  const [customizationsLoading] = usePortalCustomizations()
  const location = useLocation()
  const { theme } = useOrganizationTheme()

  const { meeting } = useCurrentCallContext()
  const { leaveMeeting } = useJoinVideoCall()

  useEffect(() => {
    if (meeting && !location.pathname.includes(routes.video)) {
      leaveMeeting()
    }
  }, [meeting, location, leaveMeeting])

  const [searchParams] = useSearchParams()
  const embedded = searchParams.get('embedded')

  const dontShowNavigation = (
      location.pathname.includes('/r/') // redirecting
   || location.pathname.includes(routes.form_submit) // direct link to form
   || embedded === 'true'
  )

  return (
    <LoadingLinear data={customizationsLoading} render={customizations => (
    <RequireTermsOfServiceAndPrivacyPolicy
      // hide when filling out direct link to form, as it's separate from portal access
      dontRequire={dontShowNavigation || !theme.requireCustomTermsOnMagicLink} 
    >
    <WithVideoToast>
    <WithFileViewer>
    <AuthenticatedContainer hideNavigation={dontShowNavigation}>
    <Routes>
      <>{genericRoutes}</>
      <Route path={routes.home} element={<Home />} />
      <Route path={routes.video} element={<VideoCall />} />
      <Route path={routes.care_plan} element={<CarePlanHome  />} />
      <Route path={routes.orders} element={<Orders  />} />

      <Route path={routes.communications}> 
        <Route path="" element={<Chat />}/> 
        <Route path=":roomId" element={<Chat />}/> 
      </Route>

      <Route path={routes.form_submit}> 
        <Route path="" element={<FormDisplay />}/> 
      </Route>


      <Route path={routes.appointment_booking}> 
        <Route path="" element={<AppointmentScheduling />}/> 
        <Route path=":pageId" element={<AppointmentSchedulingForTemplateRoute />}/> 
      </Route>

      <Route path={routes.content}> 
        <Route path="" element={<Content />}/> 
        <Route path=":id" element={<ContentViewer />}/> 
      </Route>

      <Route path={routes.events}> 
        <Route path="" element={<Events />}/> 
        <Route path=":id" element={<Event />}/> 
      </Route>

      <Route path={routes.documents}> 
        <Route path="" element={<Documents />}/> 
        <Route path={"form-responses"}> 
          <Route path="" element={<Documents />}/> 
          <Route path=":id" element={<FormResponsePage />}/> 
        </Route>
        <Route path={"outstanding-forms"} element={<OutstandingForms />} /> 
        <Route path={"submitted-forms"} element={<Grid container sx={{ mt: 2.5 }}><SubmittedForms /></Grid>} /> 
        <Route path={"files"} element={<Grid container sx={{ mt: 2.5 }}><Files /></Grid>} />
      </Route>

      <Route path={routes.forms}> 
        <Route path="" element={<Documents />}/> 
        <Route path=":accessCode" element={<SubmitForm />}/> 
      </Route>

      {/* Block community page unless it's explicitly turned on */}
      {customizations.find(c => c.page === 'Community')?.disabled === false &&
         <Route path={routes.community}> 
          <Route path="" element={<Community />}/> 
        </Route> 
      }

      <Route path={'custom'}> 
        <Route path=":pageId" element={<CustomPageIframeLoading />}/> 
      </Route>
     
      <Route path={routes.settings} element={<Settings />} />

      <Route path={routes.logout} element={<Logout />}/> 
        <Route path="/*" element={<Navigate replace to={routes.home}/>}/> 
      </Routes>
    </AuthenticatedContainer>
    </WithFileViewer>
    </WithVideoToast>
    </RequireTermsOfServiceAndPrivacyPolicy>
    )} />
  )
}

const authToken_is_valid = async (authToken: string, { host, businessId } : { host: string, businessId: string }) => {
  try {
    // only set explicit params to avoid passing through handleUnauthenticated and others
    const testSDK = new EnduserSession({ host, businessId , authToken, cacheKey: 'auth-utilities' }) // use cacheKey to avoid conflict with real localStorage state management
    testSDK.setAuthToken(authToken)
    return await testSDK.test_authenticated() === 'Authenticated!'
  } catch(err) {
    return false
  }
}

const OTPCollection = ({ authToken, onSuccess, onError } : { authToken: string, onSuccess: (args: { authToken: string, enduser: Enduser }) => void, onError: () => void }) => {
  const session = useEnduserSession()
  const [methods, setMethods] = useState<string[]>()
  const [method, setMethod] = useState('email')
  const [enteringCode, setEnteringCode] = useState(false)
  const [code, setCode] = useState('')

  // attempt to fetch otp options, if error, call onError
  useEffect(() => {
    session.api.endusers.get_otp_methods({ token: authToken }) 
    .then(({ methods }) => {
      if (!methods?.length) return onError()

      setMethods(methods)
    })
    .catch(onError)
  }, [session, onError, authToken])

  if (methods && !enteringCode) {
    return (
      <Grid container direction="column" spacing={1} alignItems="center" sx={{ pt: 3 }}>
        <Grid item sx={{ width: 300 }}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label" sx={{ color: 'black', fontWeight: 'bold' }}>
              To access, receive login code via
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              {methods.map(m => (
                <FormControlLabel key={m} 
                  value={m} 
                  control={<Radio checked={m === method} onClick={() => setMethod(m)} />} 
                  label={
                      m === 'email' ? 'Email' 
                    : m === 'sms' ? 'SMS' 
                    : m
                  } 
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item sx={{ width: 300 }}>
          <LoadingButton disabled={!method} 
            submitText='Send Code' submittingText='Sending...'
            onClick={() => 
              session.api.endusers.send_otp({ token: authToken, method })
              .then(() => setEnteringCode(true))
              .catch(onError)
            }          
          />
        </Grid> 
      </Grid>
    )
  }
  if (enteringCode) {
    return (
      <Grid container direction="column" spacing={1} alignItems="center" sx={{ pt: 3 }}>
        <Grid item sx={{ width: 300 }}>
          <Typography>Enter the code you received via {method === 'email' ? 'Email' : method === 'sms' ? 'SMS' : method}</Typography>
        </Grid>
  
        <Grid item sx={{ width: 300 }}>
          <TextField fullWidth size="small" label="Login Code"
            value={code} onChange={e => setCode(e.target.value)}
          />
        </Grid>
  
        <Grid item sx={{ width: 300 }}>
          <LoadingButton disabled={!code} 
            submitText='Submit Code' submittingText='Sending...'
            onClick={() => 
              session.api.endusers.verify_otp({ token: authToken, code }).then(onSuccess)
            }          
          />
        </Grid>
      </Grid>
    )
  }
  
  return <LinearProgress />
}

const Authenticating = ({ urlToken, onComplete } : { urlToken: string, onComplete: () => void }) => {
  const navigate = useNavigate()
  const session = useEnduserSession()

  const { refresh, updateLocalSessionInfo } = useEnduserSessionContext()
  const [authTokenForOTP, setAuthTokenForOTP] = useState('')

  const handleURLToken = useCallback(async () => {
    if (!urlToken) return // nothing to load

    const urlTokenIsValid = await authToken_is_valid(urlToken, session) 
    // always prefer to use the URL token when it's valid, even if another is cached
    if (urlTokenIsValid) {
      // make sure that the current URL token is used for refresh
      // ensures than if a new enduser is logged in on same device, that the new session is used
      session.setAuthToken(urlToken)

      // now that authToken is set, can perform authenticated requests
      // enduser value is currently unset, refresh is easy way to load without shortening existing authToken duration
      // ALSO, need a new authToken which will not be stored in URL/search history, for more securely persisting login on-device
      // if authToken is invalid, will trigger handleUnauthenticated
      refresh({ invalidatePreviousToken: true })
      .catch(console.error)
      .finally(onComplete)
    } else if (await authToken_is_valid(session.authToken, session)) { 
      onComplete()
    } else { // attempt to collect OTP for urlToken
      setAuthTokenForOTP(urlToken)
    }
  }, [urlToken, session, refresh, onComplete])

  React.useEffect(() => {
    handleURLToken().catch(e => console.error('token:', e))
  }, [handleURLToken])

  if (authTokenForOTP) {
    return (
      <OTPCollection authToken={authTokenForOTP} onError={() => navigate('/logout')} 
        onSuccess={({ enduser, authToken }) => {
          session.setUserInfo(enduser) // ensures this persists if tab closed + reopened
          updateLocalSessionInfo(enduser, authToken)
          onComplete()
        }}
      />
    )
  }
  return <LoadingPage />
}

const Routing = () => {
  const session = useEnduserSession()
  const location = useLocation()
  // const { refresh } = useEnduserSessionContext()
  const { theme } = useOrganizationTheme()
  // const lastRefreshRef = useRef(0)

  const [searchParams] = useSearchParams()
  const urlToken = searchParams.get('token')
  const [ignoreToken, setIgnoreToken] = useState('')

  const resolvingToken = urlToken && ignoreToken !== urlToken

  // useEffect(() => {
  //   if (resolvingToken) return
  //   if (!session.authToken) return
  //   if (lastRefreshRef.current > Date.now() - 10000) return
  //   lastRefreshRef.current = Date.now()

  //   refresh().catch(console.error)
  // }, [session, refresh, resolvingToken])

  if (resolvingToken) {
    return (
      <Routes>
        <Route path="*" element={<Authenticating onComplete={() => setIgnoreToken(urlToken)} urlToken={urlToken} />}/>
      </Routes>
    )
  }
  
  if (!session.authToken) return <UnauthenticatedAppRoutes {...theme.portalSettings?.authentication} />

  if (location.pathname === '/logout') return <Logout />
  if (location.pathname === '/appointment-booking-page') return <PublicAppointmentBookingPage />

  // skip onboarding entirely now
  return (
    <WithSetInitialPasswordPrompt>
    <WithVideo>
      <AuthenticatedAppRoutes />
    </WithVideo>
    </WithSetInitialPasswordPrompt>
  )

  // don't change without verifying public booking flow
  // return (
  //   (
  //     (session.userInfo.fname && session.userInfo.lname)
  //     || location.pathname.includes('book-an-appointment')
  //   )  
  //     ? (
  //       <WithSetInitialPasswordPrompt>
  //       <WithVideo>
  //         <AuthenticatedAppRoutes />
  //       </WithVideo>
  //       </WithSetInitialPasswordPrompt>
  //     )
  //     : <Onboarding />
  // )
}

export default App;
