import React, { useEffect, useState } from "react";
import { Alert, BottomNavigation, BottomNavigationAction, Button, Divider, Drawer, Grid, IconButton, MenuItem, MenuList, Paper, Tab, Tabs, Typography } from "@mui/material";
import { DisplayPictureForSelf, useCalendarEvents, useCarePlans, useMeetings, usePortalCustomizations, value_is_loaded } from "@tellescope/react-components";
import { useLocation } from "react-router-dom";
import { MOBILE_WIDTH_CUTOFF } from "../definitions/constants";
import { OrganizationLogo } from "../definitions/contexts";
import { RouteName, Routes, routes, useNavigateToPage } from "../definitions/routes";
import { ChatIcon, CloseMenuIcon, 
  CommunityIcon, 
  ContentIcon, 
  EventsIcon, 
  ForumIcon, 
  HomeIcon, 
  LogoutIcon, 
  MenuIcon, 
  OrderIcon, 
  SettingsIcon, 
  SidebarCloseIcon, 
  SideBarOpenIcon,
} from "./icons";
import { Link } from "./Link";
import { DEFAULT_PATIENT_PORTAL_BOTTOM_NAVIGATION_POSITIONS, MOBILE_BOTTOM_NAVIGATION_DISABLED_POSITION, PortalPage } from "@tellescope/types-models";
import { BookAppointmentIcon, CarePlanIcon, FormIcon } from "../definitions/icons";
import { ResponsiveModal } from "./layout";

import LayersIcon from '@mui/icons-material/Layers';
import { PortalCustomization } from "@tellescope/types-client";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const TabNavigation = ({ 
  tabs, 
} : { 
  tabs: {
    label: string,
    component: React.ReactNode,
  }[]
}) => {
  const location = useLocation()
  const storedTab = tabs.findIndex(t => t.label === window.localStorage[location.pathname + 'tab'])

  const [tab, setTab] = useState(
    storedTab !== -1 ? storedTab : 0
  )

  useEffect(() => {
    window.localStorage[location.pathname + 'tab'] = tabs[tab].label
  }, [tab, tabs, location])

  return (
    <Grid container direction="column">
      <Paper>
      <Tabs value={tab} onChange={(e, newTab) => setTab(newTab)}> 
        {tabs.map((t, i) => (
          <Tab key={t.label} label={t.label} {...a11yProps(i)} /> 
        ))}
      </Tabs>
      </Paper>

      {tabs[tab].component}
    </Grid>
  )
}

// referencing example https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
const getWindowDimensions = () => ({ 
  width: window.innerWidth, height: window.innerHeight 
})
export const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => { setDimensions(getWindowDimensions()) }

    window.addEventListener('resize', handleResize);
    return () => { window.removeEventListener('resize', handleResize) }
  }, []);

  return dimensions;
}
export const useWindowWidth = () => useWindowDimensions().width
export const useWindowHeight = () => useWindowDimensions().height

export const useFullHeight = () => (
  useIsMobile() ? '83vh' : '90vh'
)

export const useIsMobile = () => useWindowWidth() < MOBILE_WIDTH_CUTOFF

type Page <K extends keyof Routes> = { 
  value: Routes[K],
  Icon: typeof ForumIcon | typeof HomeIcon,
  label: string,
}
const buildNavigationInfo = <T extends { [K in RouteName]?: Page<K> }> (t: T) => t

export const navigationInfo = buildNavigationInfo({
  home: {
    value: routes.home,
    label: "Home",
    Icon: HomeIcon,
  },
  communications: {
    value: routes.communications,
    label: "Communications",
    Icon: ChatIcon,
  },
  content: {
    value: routes.content,
    label: "Education",
    Icon: ContentIcon,
  },
  documents: {
    value: routes.documents,
    label: "Documents",
    Icon: FormIcon,
  },
  community: {
    value: routes.community,
    label: "Community",
    Icon: CommunityIcon,
  },
  care_plan: {
    value: routes.care_plan,
    label: "Care Plan",
    Icon: CarePlanIcon,
  },
  events: {
    value: routes.events,
    label: "My Events",
    Icon: EventsIcon,
  },
  appointment_booking: {
    value: routes.appointment_booking,
    label: "Book an Appointment",
    Icon: BookAppointmentIcon,
  },
  orders: {
    value: routes.orders,
    label: "Orders",
    Icon: OrderIcon ,
  },
  settings: {
    value: routes.settings,
    label: "Settings",
    Icon: SettingsIcon,
  },
  logout: {
    value: routes.logout,
    label: "Log Out",
    Icon: LogoutIcon,
  },
})

type NavigationPages = (typeof navigationInfo)[keyof typeof navigationInfo][] 
export const primaryNavigationPages: NavigationPages = [
  navigationInfo.home,
  navigationInfo.communications,
  navigationInfo.documents,
  navigationInfo.content,
]

const useMobileFooterNavigationPages = () => {
  const [customizationsLoading] = usePortalCustomizations()

  const [pages, setPages] = useState<NavigationPages>([])

  useEffect(() => {
    if (!value_is_loaded(customizationsLoading)) return 

    const pages: NavigationPages = []
    
    const indexForPages: { [P in PortalPage] : number } = {
      "Home": 0,
      "Care Plan": customizationsLoading.value.find(c => c.page === 'Care Plan')?.mobileBottomNavigationPosition ?? DEFAULT_PATIENT_PORTAL_BOTTOM_NAVIGATION_POSITIONS['Care Plan'],
      Communications: customizationsLoading.value.find(c => c.page === 'Communications')?.mobileBottomNavigationPosition ?? DEFAULT_PATIENT_PORTAL_BOTTOM_NAVIGATION_POSITIONS['Communications'],
      Community: customizationsLoading.value.find(c => c.page === 'Community')?.mobileBottomNavigationPosition ?? DEFAULT_PATIENT_PORTAL_BOTTOM_NAVIGATION_POSITIONS['Community'],
      Documents: customizationsLoading.value.find(c => c.page === 'Documents')?.mobileBottomNavigationPosition ?? DEFAULT_PATIENT_PORTAL_BOTTOM_NAVIGATION_POSITIONS['Documents'],
      Education: customizationsLoading.value.find(c => c.page === 'Education')?.mobileBottomNavigationPosition ?? DEFAULT_PATIENT_PORTAL_BOTTOM_NAVIGATION_POSITIONS['Education'], 
      "My Events": customizationsLoading.value.find(c => c.page === 'My Events')?.mobileBottomNavigationPosition ?? DEFAULT_PATIENT_PORTAL_BOTTOM_NAVIGATION_POSITIONS['My Events'], 
      'Appointment Booking': customizationsLoading.value.find(c => c.page === 'Appointment Booking')?.mobileBottomNavigationPosition ?? DEFAULT_PATIENT_PORTAL_BOTTOM_NAVIGATION_POSITIONS['Appointment Booking'], 
      'Orders': customizationsLoading.value.find(c => c.page === 'Orders')?.mobileBottomNavigationPosition ?? DEFAULT_PATIENT_PORTAL_BOTTOM_NAVIGATION_POSITIONS['Orders'], 
    }

    for (const page in indexForPages) {
      if (indexForPages[page as PortalPage] === MOBILE_BOTTOM_NAVIGATION_DISABLED_POSITION) continue
     
      const pageInfo = Object.values(navigationInfo).find(i => i.label === page
        || (page === 'Appointment Booking' && i.label === navigationInfo.appointment_booking.label)
        || (page === 'Events' && i.label === navigationInfo.appointment_booking.label)
      )
      if (!pageInfo) {
        console.warn('missing', page)
        continue
      }

      pages.push(pageInfo)
    }

    setPages(pages.sort((p1, p2) => indexForPages[p1.label as PortalPage] - indexForPages[p2.label as PortalPage]))
  }, [customizationsLoading])

  return pages
}

export const usePageCustomizations = () => {
  const [customizationsLoading] = usePortalCustomizations()
  return {
    getPageLabel: (page?: string) => (
      value_is_loaded(customizationsLoading) 
        ? (
          customizationsLoading.value.find(c => 
             c.page === page
          || (c.page === 'Appointment Booking' && page === navigationInfo.appointment_booking.label)
          )?.title ?? page
        )
        : page
    ),
    pageIsEnabled: (page?: PortalPage | 'Book an Appointment') => (
      value_is_loaded(customizationsLoading) 
        ? !(customizationsLoading.value.find(
            c => c.page === page
              || (c.page === 'Appointment Booking' && page === 'Book an Appointment')
          )?.disabled) && (
            // these pages should be disabled by default (when no customization is defined)
            page !== 'Orders' || !!customizationsLoading.value.find(v => v.page === 'Orders')
          )
        : false
    )
  }
}

const usePageForLocation = () => { 
  const mobilePages = useMobileFooterNavigationPages()
  const location = useLocation()

  for (const page of mobilePages) {
    if (location.pathname.includes(page.value)) return page
  }

  return {} as Record<string, undefined>
}

const get_custom_page_info = (v: PortalCustomization, isActive=window.location.href.includes(v.id)) => ({
  label: v.page,
  value: `/custom/${v.id}`,
  Icon: (
    v.iconURL
      ? () => (
        <img src={isActive ? (v.activeIconURL || v.iconURL) : v.iconURL} 
          style={{ width: 20, height: 20, paddingRight: 12 }} alt={`${v.page} icon`} 
        />
      )
      : LayersIcon
  ),
})

const BOTTOM_NAVIGATION_HEIGHT = '56px' // default in mui
const WithBottomNavigation = ({ children } : { children: React.ReactNode }) => {
  const [customizationsLoading] = usePortalCustomizations()
  const { getPageLabel, pageIsEnabled } = usePageCustomizations()
  const mainNavigationPages = useMobileFooterNavigationPages()
  const navigate = useNavigateToPage()
  const currentPage = usePageForLocation()

  const handleChange = (_: React.SyntheticEvent, newPage: Page<any>['value']) => {
    navigate(newPage)
  };

  const [drawerOpen, setDrawerOpen] = useState(false)

  const enabledPages = mainNavigationPages.filter(page => pageIsEnabled(page.label as PortalPage))
  return (
    <>
    <Paper elevation={2}>
    <Grid container alignItems="center" justifyContent="space-between" sx={{ 
      padding: 1,
    }}>
      <OrganizationLogo height={25} style={{ marginLeft: 5 }} />

      <Typography style={{ marginRight: 10, fontWeight: 'bold' }}>
        {getPageLabel(currentPage.label)}
      </Typography>

      <IconButton
        id="demo-customized-button"
        aria-controls={drawerOpen ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={drawerOpen ? 'true' : undefined}
        onClick={() => setDrawerOpen(o => !o)}
      >        
        <DisplayPictureForSelf />
      </IconButton>

      <Drawer anchor={'left'} open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <Grid container direction="column" style={{ minWidth: 200, width: '70vw' }}>
          <Grid container alignItems="center" justifyContent="space-between" sx={{
            padding: 2,
          }}>
            <Typography style={{ fontWeight: 'bold', fontSize: 18 }}>
              Navigation
            </Typography>
            
            <IconButton onClick={() => setDrawerOpen(false)}>
              <CloseMenuIcon />
            </IconButton>
          </Grid>

          {enabledPages.map(p => (
            <MenuItemForPage page={p} /> 
          ))}

          {value_is_loaded(customizationsLoading) &&
              customizationsLoading.value.filter(v => v.iframeURL).map(v => (
                <MenuItemForPage key={v.id}
                  page={get_custom_page_info(v)}
                /> 
              ))
            } 

          <Divider flexItem />

          <MenuItemForPage page={navigationInfo.settings} />

          <MenuItemForPage page={navigationInfo.logout} />
        </Grid>
      </Drawer>
    </Grid>
    </Paper>
    
    <Grid container sx={{ marginBottom: BOTTOM_NAVIGATION_HEIGHT }}>
      {children}
    </Grid>

    {(enabledPages.length ?? 0) > 1 && 
    <Paper elevation={5} sx={{ 
      position: 'fixed', bottom: 0, left: 0, right: 0,
      height: BOTTOM_NAVIGATION_HEIGHT
    }}>
      <BottomNavigation value={currentPage.value} onChange={handleChange} showLabels>
        {enabledPages.map((page, i) => i > 3 ? null : (
          <BottomNavigationAction key={page.value} value={page.value} icon={<page.Icon />} 
            label={getPageLabel(page.label)} 
            sx={{ '.MuiBottomNavigationAction-label': { fontSize: 12 } }}
          />
        ))}
        {enabledPages.length > 4 && 
          <BottomNavigationAction icon={<MenuIcon />} 
            onClick={() => setDrawerOpen(true)}
            label={"More"} 
            sx={{ '.MuiBottomNavigationAction-label': { fontSize: 12 } }}
          />
        }
      </BottomNavigation>
    </Paper>
    }
    </>
  );
}

const SIDEBAR_ICON_SIZE = 25;

const MenuItemForPage = ({ page, iconOnly, onClick } : { page: Page<any>, iconOnly?: boolean, onClick?: () => void }) => {
  const { getPageLabel, pageIsEnabled } = usePageCustomizations()
  const navigate = useNavigateToPage()
  const location = useLocation()

  if (!pageIsEnabled(page.label as PortalPage)) return null

  const isSelected = location.pathname.includes(page.value)

  return (
    <MenuItem key={page!.label} style={{ paddingTop: 8, paddingBottom: 8 }}
      // selected={location.pathname.includes(p.value)}
      onClick={() => {
        if (onClick) return onClick()

        navigate(page.value)
      }}
    >
    <Grid container alignItems="center">
      {<page.Icon color={isSelected ? 'primary' : 'inherit'} 
        style={{ 
          fontSize: SIDEBAR_ICON_SIZE,
          marginRight: 8,
          opacity: isSelected ? 1 : .4 
        }} 
      />}

      {!iconOnly && 
        <Typography style={{ 
          opacity: isSelected ? 1 : .7 
        }}>
          {getPageLabel(page!.label)}  
        </Typography>
      }
    </Grid>
    </MenuItem>

  )
}


const TOP_HEIGHT = 75
const WithSidebar = ({ children } : { children: React.ReactNode }) => {
  const [careplansLoading] = useCarePlans()
  const [customizationsLoading] = usePortalCustomizations()
  const [loggingOut, setLoggingOut] = useState(false)
  const navigate = useNavigateToPage()

  const {
    sidebarOpen: open,
    setSidebarOpen: setOpen,
  } = useNavigationContext()

  const width = open ? 300 : 57

  return (
    <>
    <ResponsiveModal open={loggingOut} setOpen={setLoggingOut} maxWidth={650}>
    <Grid container justifyContent={"center"}>
    <Grid container direction="column" sx={{ maxWidth: 450, py: 5 }} spacing={1}>
      <Grid item>
        <Typography sx={{ mb: 3, fontSize: 20}}>
          Are you sure you want to logout?
        </Typography>
      </Grid>

      <Grid item sx={{ width: '100%' }} onClick={() => navigate(routes.logout)}>
        <Button fullWidth variant="contained">
          Yes, Logout
        </Button>
      </Grid>

      <Grid item sx={{ width: '100%' }}>
        <Button fullWidth variant="outlined" onClick={() => setLoggingOut(false)}>
          Cancel
        </Button>
      </Grid>
    </Grid>      
    </Grid>
    </ResponsiveModal>

    <Grid container>
      <Grid item style={{ width }}>
      <Paper elevation={5} style={{ margin: 0, position: 'fixed', left: 0, top: 0, }}>
      <Grid container direction="column" style={{ height: '100vh' }}>
        <Grid item>
          {open && 
            <>
            <Grid container alignItems="center" justifyContent="center"
              style={{ padding: 5, height: TOP_HEIGHT }}
            >
              <OrganizationLogo height={''} imgStyle={{
                maxHeight: 35,
                maxWidth: 250,
              }} />
            </Grid> 
            <Divider flexItem />
            </>
          }

          <MenuList
            variant="selectedMenu"
            sx={{
              flexGrow: 0,
              width,
              overflowX: 'none',
              overflowY: 'auto',
            }}
          >
            {primaryNavigationPages.map(p => <MenuItemForPage key={p.label} page={p} iconOnly={!open} />)}
            
            {value_is_loaded(careplansLoading) && careplansLoading.value.length > 0 &&
              <MenuItemForPage page={navigationInfo.care_plan} iconOnly={!open} /> 
            }
            <MenuItemForPage page={navigationInfo.events} iconOnly={!open} /> 
            <MenuItemForPage page={navigationInfo.appointment_booking} iconOnly={!open} /> 
            <MenuItemForPage page={navigationInfo.orders} iconOnly={!open} /> 
            {value_is_loaded(customizationsLoading) && customizationsLoading.value.find(p => p.page === 'Community')?.disabled === false && // disabled by default
              <MenuItemForPage page={navigationInfo.community} iconOnly={!open} /> 
            }

            {value_is_loaded(customizationsLoading) &&
              customizationsLoading.value.filter(v => v.iframeURL).map(v => (
                <MenuItemForPage key={v.id}
                  page={get_custom_page_info(v)} 
                  iconOnly={!open}                 
                /> 
              ))
            }

            <MenuItemForPage page={navigationInfo.settings} iconOnly={!open} />

            <Divider style={{ marginBottom: 8, width }} />

            <MenuItemForPage page={navigationInfo.logout} iconOnly={!open} onClick={() => setLoggingOut(true)} />
          </MenuList>
        </Grid>

        <Grid container alignItems="center" justifyContent={'center'} style={{ marginTop: 'auto', marginBottom: 10 }}>
          {open 
            ? (
              <Button onClick={() => setOpen(o => !o)} style={{ opacity: 0.6 }} color="inherit">
                <SidebarCloseIcon style={{ fontSize: SIDEBAR_ICON_SIZE }} /> 
                <Typography style={{ marginLeft: 10, textTransform: 'none' }}>
                  Toggle sidebar
                </Typography>
              </Button>
            ) : (
              <IconButton onClick={() => setOpen(o => !o)}>
                <SideBarOpenIcon style={{ fontSize: SIDEBAR_ICON_SIZE }} />
              </IconButton>
            )
          }
        </Grid>
      </Grid>
      </Paper>
      </Grid>

      <Grid item style={{ width: `calc(100% - ${width}px)`, marginTop: open ? TOP_HEIGHT : 0 }}>
        {open && <Divider flexItem />}

        <Grid container>
          {children}
        </Grid>
      </Grid>
    </Grid>
    </>
  )
}

const NavigationContext = React.createContext({} as {
  sidebarOpen: boolean,
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>,
})
export const useNavigationContext = () => React.useContext(NavigationContext)

export const AuthenticatedContainer = ({ children, hideNavigation } : HasChildren & { hideNavigation: boolean }) => {
  const width = useWindowWidth()
  const [sidebarOpen, setSidebarOpen] = useState(true)

  if (hideNavigation) return <>{children}</>
  return (
    <NavigationContext.Provider value={{ sidebarOpen, setSidebarOpen }}>
      {width < MOBILE_WIDTH_CUTOFF
        ? <WithBottomNavigation>{children}</WithBottomNavigation>
        : <WithSidebar>{children}</WithSidebar>
      }
    </NavigationContext.Provider>
  )
}

const proximityToNow = (n: number) => Math.abs(n - Date.now()) 

export const WithVideoToast = ({ children } : { children: React.ReactNode }) => {
  const location = useLocation()
  const [eventsLoading] = useCalendarEvents()
  const [, { findById: findMeeting }] = useMeetings()
  const [closedMeeting, setClosedMeeting] = useState<string>('')
  const isMobile = useIsMobile()

  if (location.pathname.includes(routes.video)) return <>{children}</>
  if (!value_is_loaded(eventsLoading)) return <>{children}</>

  const liveEvent = (
    (
      [...eventsLoading.value]
      .sort((e1, e2) => proximityToNow(e1.startTimeInMS) - proximityToNow(e2.startTimeInMS))
    )
    .find(e => 
       e.enableVideoCall
    && e.meetingStatus === 'live'
    && Date.now() - (e.startTimeInMS) < (1000 * 60 * 60 * 2)
    )
  )

  const liveMeeting = findMeeting(liveEvent?.meetingId ?? '')

  const handleClose = () => setClosedMeeting(liveMeeting?.id ?? liveEvent?.id ?? '')

  return (
    <>
    {(liveEvent || liveMeeting) && (closedMeeting !== liveEvent?.id && closedMeeting !== liveMeeting?.id) && (
      <Alert onClose={handleClose} severity="info" sx={{ 
        position: 'fixed',
        bottom: isMobile ? 75 : 15,
        right: 15,
        zIndex: 1000,
       }}>
        Join your upcoming call 
        - <Link to={routes.video} query={{ 
            meetingId: liveMeeting?.id ?? '', 
            calendarEventId: liveEvent?.id ?? '',
          }}>
            here
          </Link>
      </Alert>
    )}
    {children}
    </>
  )
}