import React, { useEffect, useRef, useState } from "react"
import { LabeledIconButton, LoadingLinear, ResponseAnswer, ScrollingList, useEnduserSession, useFormResponses, useResolvedSession, useUsers, value_is_loaded } from "@tellescope/react-components"
import { Enduser, FormResponse } from "@tellescope/types-client"
import { routes, useNavigateToPage } from "../../definitions/routes"
import { ResponsiveModal } from "../../components/layout"
import { Badge, Button, Divider, Grid, Paper, Typography } from "@mui/material"
import { mm_dd_yyyy, user_display_name } from "@tellescope/utilities"
import CloseIcon from '@mui/icons-material/Close';
import { FormIcon, WithCircularBackground } from "../../definitions/icons"
import { ViewMenuIconButton } from "../../components/ViewMenuIconButton"
import { DASHBOARD_CHILD_MARGIN } from "../../definitions/constants"

// simplified and more mobile friendly than the Library implementation
interface FormResponse_T {
  enduser?: Enduser,
  onClose?: () => void,
  hideHeader?: boolean,
  response: FormResponse,
  id?: string,
}
// this should use all vanilla React / inline styles to ensure printing is consistent
export const FormResponseView = ({ enduser, onClose, hideHeader, response, id, printing } : FormResponse_T & { printing ?: boolean }) => {
  const session = useEnduserSession()
  const [, { findById: findUser }] = useUsers({ dontFetch: true })
  if (response.responses === undefined || response.responses.length === 0) {
    return <Typography>Awaiting Response</Typography> 
  }

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', minWidth: 250, maxWidth: 650 }} id={id}>
      <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        {!hideHeader && 
          <Grid container direction="column">
            <h2 style={{
              fontSize: 20,
              fontWeight: 'bold',
              margin: 0, padding: 0,
            }}> 
              {response.formTitle}
            </h2>

            {response.submittedBy && response.submittedBy !== session.userInfo.id &&
              <Typography sx={{ mt: 1 }}>
                {user_display_name(findUser(response.submittedBy))}
              </Typography>
            }
          </Grid>
        }

        {onClose 
          ? <LabeledIconButton Icon={CloseIcon} label="Close" onClick={onClose} />
          : <Grid />
        }
      </div>

      <Divider flexItem style={{ marginTop: 2, marginBottom: 12 }} />

      <div style={{ flexDirection: "column", display: 'flex', flex: 1 }}>
      {response.responses.map((r, i) => (
          <div key={i} style={{ marginBottom: 12 }}>
            <div style={{ display: 'flex', flex: 1, flexDirection: "row", justifyContent: 'space-between', flexWrap: 'nowrap' }}>
              {r.fieldTitle && 
                <div style={{ }}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {r.fieldTitle}
                  </Typography>
                </div>
              }
            </div>

            {r.fieldDescription
              ? (
                <Typography style={{  }}>
                  {r.fieldDescription}
                </Typography>
              ): r.fieldHtmlDescription
                ? (
                  <div dangerouslySetInnerHTML={{
                    __html: r.fieldHtmlDescription
                  }} />
                )
                : null
            } 

            <ResponseAnswer answer={r.answer} isHTML={r.answerIsHTML} />
          </div>
        )
      )}
      </div>
    </div>
  )
}

const printToPDF_ID = 'print-to-pdf'
export const PrintToPDF = ({ children, id='print-to-pdf', title, onPrint } : { id?: string, children: React.ReactNode, title: string, onPrint: () => void }) => {
  const didPrintRef = useRef(false)

  useEffect(() => {
    const i = setInterval(() => {
      if (didPrintRef.current) return

      const printDiv = window.document.getElementById(id)
      if (!printDiv) return

      const printWindow = window.open('', '', 'height=1100,width=850');
      if (!printWindow) return

      didPrintRef.current = true

      printWindow.document.write(`<html><head><title>${title}</title>`);
      printWindow.document.write('</head><body >');
      printWindow.document.write(printDiv.innerHTML);
      printWindow.document.write('</body></html>');
      printWindow.document.close();

      setTimeout(() => {
        printWindow.print();
      }, 2000)
      
      onPrint()
    }, 100) // page may need time to load before printable

    return () => { clearInterval(i) }
  }, [onPrint, id, title])

  if (id) return <>{children}</>
  return (
    <div id={printToPDF_ID}>
      {children}
    </div>
  )
}

export const ResponseCardContent = ({ response } : { response: FormResponse }) => {
  const navigate = useNavigateToPage()
  const session = useEnduserSession()
  const [open, setOpen] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [, { updateElement: updateResponse }] = useFormResponses()

  return (
    <>
    {downloading &&
      <PrintToPDF id={printToPDF_ID} title={response.formTitle} onPrint={() => setDownloading(false)}>
      <div style={{ display: 'none' }}>
        <FormResponseView printing response={response} id={printToPDF_ID} enduser={session.userInfo} />
      </div>
      </PrintToPDF>
    }

    <ResponsiveModal open={!!open} setOpen={setOpen} maxWidth={'750px'}>
    <Grid container justifyContent={"center"}>
      <FormResponseView enduser={session.userInfo} onClose={() => setOpen(false)} 
        // when a specific field is sharedWithEnduser, only show sharedWithEnduser fields
        response={
          response?.responses?.find(r => r.sharedWithEnduser === true)
            ? {
              ...response, responses: response.responses.filter(r => r.sharedWithEnduser)
            }
            : response
        }
      />
    </Grid>
    </ResponsiveModal>

    <Grid container justifyContent="space-between" wrap="nowrap"
      sx={{ cursor: !response.submittedAt ? 'pointer' : '' }}
      onClick={() => {
        if (response.submittedAt) return
        navigate(routes.forms, { id: response.accessCode })
      }}
    >
      <Grid item>
      <Grid container alignItems="center" wrap="nowrap">
        <Grid item sx={{ mr: 1 }}>
          <WithCircularBackground>
            {response.submittedAt
              ? <FormIcon />
              : (
                <Badge variant="dot" badgeContent={1} color="primary">
                  <FormIcon color="primary" />
                </Badge>
              )
            }
          </WithCircularBackground>
        </Grid>

        <Grid container direction="column" 
          sx={{ 
            cursor: response.submittedAt ? 'pointer' : undefined,
            textDecoration: response.submittedAt ? 'underline' : undefined,
          }}
          onClick={() => {
            if (response.submittedAt) {
              return setOpen(true)
            }
          }}
        >
          <Typography style={{ fontSize: 18 }}>
            {response.formTitle}
          </Typography>

          {response.submittedAt &&
            <Typography style={{ fontSize: 14, opacity: 0.8 }}>
              Uploaded {mm_dd_yyyy(new Date(response.submittedAt))}
            </Typography>
          }
        </Grid>
      </Grid>
      </Grid>

      <Grid item>
        {response.submittedAt &&
          <ViewMenuIconButton open={menuOpen} setOpen={setMenuOpen}
          entries={[
            {
              label: "View Responses",
              onClick: () => setOpen(true),
            },
            {
              label: "Download",
              onClick: () => setDownloading(true),
            },
            {
              label: "Hide",
              onClick: () => (
                updateResponse(response.id, { hideFromEnduserPortal: true }).catch(console.error)
              ),
              hidden: session.userInfo.businessId !== '61aa4fef95cdafe5bb9d8b37' // hide from non-Icon prod portals
            }
          ]}
        />
      }
      </Grid>
    </Grid>
    </>
  )
}

export const SubmittedForms = ({ limit } : { limit?: number }) => {
  const navigate = useNavigateToPage()
  const session = useResolvedSession()
  const [, { filtered: filteredResponses, doneLoading: doneLoadingResponses, loadMore: loadMoreResponses }] = useFormResponses({ }) // loads unsubmitted forms as well
  const formResponsesLoading = filteredResponses(f => 
    !f.hideFromEnduserPortal
    && !(!f.submittedAt && !!f.isInternalNote) // hide unsubmitted internal notes from portal
    && (
      f.submittedBy === session.userInfo.id  // form submitted by enduser
    || (!f.submittedBy && !f.draftSavedAt) // form outstanding (and not saved draft by user)
    || (!!f.responses.find(r => r.sharedWithEnduser === true))
    )
  )

  const outstandingForms = (
    value_is_loaded(formResponsesLoading) 
      ? formResponsesLoading.value.filter(f => !f.submittedAt && !f.isInternalNote) 
      : [] 
  )

  return (
    <LoadingLinear data={formResponsesLoading} render={responses => {
      const submittedForms = responses.filter(_f => 
        !outstandingForms.find(f => f.id === _f.id) 
      )

      return (
        <>
        <ScrollingList items={limit ? submittedForms.slice(0, limit) : submittedForms} 
          doneLoading={doneLoadingResponses} loadMore={loadMoreResponses}
          title="Forms & Notes" emptyText="Your submitted forms and shared notes will appear here"
          titleStyle={{ paddingLeft: DASHBOARD_CHILD_MARGIN }}
          itemContainerStyle={{
            padding: DASHBOARD_CHILD_MARGIN,
            paddingTop: 2,
          }}
          Item={({ item }) => (
            <Paper elevation={5} sx={{ padding: 2, borderRadius: 4, marginBottom: 1 }}>
              <ResponseCardContent response={item} />
            </Paper>
          )}
        />
        {limit && submittedForms.length > limit && 
          <Button variant="outlined" onClick={() => navigate(routes.submitted_forms)} sx={{ width: 200, borderRadius: 3 }}>
            View All Responses
          </Button>
        }
        </>
    )}} />
  )
}